<template>
  <div class="container">
    <div class="container_block">
      <div class="container_block_top">
        <div class="container_block_top_firstline">
          <div class="container_block_top_firstline_left">
            <span style="margin-right:10px"><img
                src="../../assets/images/tags.png"
                alt=""
              ></span>
            <span>收货人信息</span>
          </div>
          <!--          <div class="container_block_top_firstline_right">
            新增收货地址
          </div>-->
        </div>
        <div
          class="container_block_top_addressList"
          v-show="showAddress"
        >
          <div
            :class="[item.checked===true ? 'container_block_top_addressList_item--default': 'container_block_top_addressList_item--unselect']"
            v-for="(item,idex) in addressList"
            :key="item.id"
            @click="checkClick(idex)"
          >
            <span><img
                src="../../assets/images/checked.png"
                alt=""
              ></span>
            <span>{{item.name}}</span>
            <span>{{item.addressName}}{{item.address}}</span>
          </div>
          <!-- <div class="container_block_top_addressList_item container_block_top_addressList_item--unselect">
                        <span><img src="../../assets/images/checked.png" alt=""></span>
                        <span>张文涛</span>
                        <span>山东德州市德城区长河街道广川大道星凯国际C区12号楼</span>
                    </div> -->
        </div>
        <div
          class="container_block_top_collaps"
          @click="clickShowAddress"
        >
          <span v-if="showAddress===true">收起地址</span>
          <span v-else-if="showAddress===false">展开地址</span>
          <span><img
              src="../../assets/images/collaps.png"
              alt=""
            ></span>
        </div>

      </div>
      <div class="container_block_center">
        <div class="container_block_center_firstline">
          <span style="margin-right:10px"><img
              src="../../assets/images/carts.png"
              alt=""
            ></span> <span>购物清单</span>
        </div>
        <el-divider></el-divider>

        <div
          class="container_block_itemwraper"
          v-for="item in list"
          :key="item"
        >
          <div class="container_block_item">
            <!-- <div class="container_block_item_check">
                    <el-checkbox v-model="checked1"  size="large" />
                </div> -->
            <div class="container_block_item_pic">
              <img
                :src="item?.vlShopGoods?.images[0]"
                alt=""
              >
            </div>
            <div class="container_block_item_info">
              <div class="container_block_item_info_title">
                {{item?.vlShopGoods?.name}}
              </div>
              <template v-if="item?.vlShopGoods?.payType==='1'">
                <div class="container_block_item_info_price">
                  <span class="container_block_item_info_price_tag">&yen;{{item?.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}}</span> <span class="container_block_item_info_price_unit">/{{item.vlShopGoods?.vlUnit?.unit}}</span>
                </div>
                <div
                  class="container_block_item_info_score"
                  v-show="false"
                >
                  积分：- {{item.vlShopGoods.score}}
                </div>
              </template>
              <template v-if="item?.vlShopGoods?.payType==='2'">
                <div class="container_block_item_info_price">
                  <span
                    class="container_block_item_info_price_tag"
                    v-show="false"
                  >&yen;{{item?.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}}</span> <span class="container_block_item_info_price_unit">/{{item.vlShopGoods?.vlUnit?.unit}}</span>
                </div>
                <div class="container_block_item_info_score">
                  积分：- {{item.vlShopGoods.score}}
                </div>
              </template>
              <template v-if="item?.vlShopGoods?.payType==='3'">
                <div class="container_block_item_info_price">
                  <span class="container_block_item_info_price_tag">&yen;{{item?.vlShopGoods?.isRebate==='1'? item.vlShopGoods?.originalPrice : item.vlShopGoods?.discountPrice}}</span> <span class="container_block_item_info_price_unit">/{{item.vlShopGoods?.vlUnit?.unit}}</span>
                </div>
                <div class="container_block_item_info_score">
                  积分：- {{item.vlShopGoods.score}}
                </div>
              </template>

            </div>

            <div>
              <el-input-number
                v-model="item.num"
                :min="1"
                :max="10"
                disabled
                @change="handleChange"
              />
            </div>

          </div>
          <el-divider></el-divider>
        </div>

      </div>
      <div class="container_block_bottom">
        <div class="container_block_center_firstline">
          <span style="margin-right:10px"><img
              src="../../assets/images/carts.png"
              alt=""
            ></span> <span>付款</span>
        </div>
        <div class="container_block_bottom_btns">
          <div
            @click="chooseItem(index)"
            :class="[item.active===true ?'container_block_bottom_btns_item--choosed' : 'container_block_bottom_btns_item']"
            v-for="(item,index) in cartList"
            :key="index"
          ><span style="margin-right: 15px;"><img
                src="../../assets/images/wxpay.png"
                alt=""
              ></span> <span style="margin-right: 75px;">{{item.name}}</span> <span v-show="item.active"><img
                src="../../assets/images/checked.png"
                alt=""
              ></span></div>
          <!-- <div class="container_block_bottom_btns_item container_block_bottom_btns--normal"><span style="margin-right: 15px;"><img src="../../assets/images/wxpay.png" alt=""></span> <span style="margin-right: 75px;">微信支付</span> <span><img src="../../assets/images/checked.png" alt=""></span></div>
                    <div class="container_block_bottom_btns_item container_block_bottom_btns--normal"><span style="margin-right: 15px;"><img src="../../assets/images/wxpay.png" alt=""></span> <span style="margin-right: 75px;">微信支付</span> <span><img src="../../assets/images/checked.png" alt=""></span></div>
                    <div class="container_block_bottom_btns_item container_block_bottom_btns--normal"><span style="margin-right: 15px;"><img src="../../assets/images/wxpay.png" alt=""></span> <span style="margin-right: 75px;">微信支付</span> <span><img src="../../assets/images/checked.png" alt=""></span></div> -->
        </div>
        <div class="container_block_bottom_sub">
          <el-button
            type="warning"
            @click="confirmPay"
          >确认支付&yen;{{total}} +积分{{totalScore}}</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="payDialogVisible"
      title="支付"
      :before-close="closePayDialog"
    >

      <div class="paydialog">
        <div class="paydialog_recharge">应付金额<span class="paydialog_left_recharge_money">&yen;{{total}}</span></div>
        <div class="paydialog_qrcode">
          <qrcode-vue
            :value="qrCodeUrl"
            :size="230"
            level="H"
          />
          <!-- <img
            src="../../assets/images/qrcode.png"
            alt=""
          > -->

        </div>
        <div class="paydialog_btn"><span><img
              src="../../assets/images/scan.png"
              alt=""
            ></span><span>打开微信，扫描二维码支付</span></div>
      </div>

    </el-dialog>
  </div>
</template>
<script setup name="ShopPay">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { wxPay } from '../../api/wxreq';
import { getAddressList } from '../../api/shop';
import router from "@/router";
import QrcodeVue from 'qrcode.vue'
import { ElMessage, ElMessageBox } from "element-plus";
import { getUserInfo } from "@/api/user";
//import {getLengthUserRange} from "@/api/vipsetting";
import { socketUrl } from "../../utils/URL"
import { getUserId } from "../../utils/auth"

const qrCodeUrl = ref('')
const orderId = ref('')
const payDialogVisible = ref(false)

const checked = ref('1')
//const address = ref('')
const showAddress = ref(true)
const addressList = ref([])
const addressId = ref();
const list = ref([])
const total = ref(0)
const totalScore = ref(0)
const user = ref({})
const route = useRoute();

console.log("listss==", JSON.parse(route.params.list))
console.log("total===", route.params.totalPrice)
const clickShowAddress = () => {
  showAddress.value = !showAddress.value
}
const getDefaultAddr = async () => {
  let result = await getAddressList()
  list.value = JSON.parse(route.params.list)
  total.value = route.params.totalPrice
  totalScore.value = route.params.totalScore
  console.log(result)
  if (result.code === 200) {
    addressList.value = result.data
    if (addressList.value.length > 0) {
      addressId.value = addressList.value[0].id;
    }
    for (let item of addressList.value) {
      if (item.isDefault === "0") {
        item.checked = true
      } else {
        item.checked = false
      }
    }
  }

}
getDefaultAddr();

const checkClick = (index) => {
  addressList.value[index].checked = true
  for (let i in addressList.value) {
    if (i != index) {
      addressList.value[i].checked = false
    }
  }
}


const cartList = ref([{ id: 1, name: "微信支付", active: true }, { id: 2, name: "余额支付", active: false }, { id: 3, name: "支付宝支付", active: false }, { id: 4, name: "积分支付", active: false }])
const chooseItem = (index) => {
  if (index == 3&&total.value != 0 && totalScore.value != 0) {
    ElMessage.error("此商品不支持使用积分支付,请选择其他支付方式");
    return;
  }
  //判断商品是否可以积分支付
  if (total.value != 0 && totalScore.value == 0 && index == 3) {
    ElMessage.error("此商品不支持使用积分支付,请选择其他支付方式");
    return;
  }
  //如果商品是有积分，没有钱，那么，钱相关的应该也不能选
  if (total.value == 0 && totalScore.value != 0 && index == 0) {
    ElMessage.error("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if (total.value == 0 && totalScore.value != 0 && index == 1) {
    ElMessage.error("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if (total.value == 0 && totalScore.value != 0 && index == 2) {
    ElMessage.error("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if (user.value.money == null || user.value.money / 100 < total.value) {
    if (index == 1) {
      ElMessage.error("您的余额不足，不能使用余额支付");
      return;
    }
  }
  cartList.value[index].active = true
  checked.value = cartList.value[index].id;
  console.error("111--" + checked.value);
  console.log("index===", cartList.value[index].active)
  for (let i in cartList.value) {
    if (i != index) {
      cartList.value[i].active = false
    } else {
      cartList.value[i].active = true
    }
  }
}

const handleChange = () => {

}

const getUser = async () => {
  console.error("执行了");
  let response = await getUserInfo();
  user.value = response.data
}
getUser();
var timer = null
var socket;
const confirmPay = async () => {

  console.error("check-->" + checked.value);
  if (total.value != 0 && totalScore.value == 0 && checked.value == "4") {
    ElMessage.error("此商品不支持使用积分支付,请选择其他支付方式");
    return;
  }
  //如果商品是有积分，没有钱，那么，钱相关的应该也不能选
  if (total.value == 0 && totalScore.value != 0 && checked.value == "1") {
    ElMessage.error("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if (total.value == 0 && totalScore.value != 0 && checked.value == "2") {
    ElMessage.error("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if (total.value == 0 && totalScore.value != 0 && checked.value == "3") {
    ElMessage.error("此商品只支持积分支付,请选择积分支付");
    return;
  }
  if (user.value.money == null || user.value.money / 100 < total.value) {
    if (checked.value == 2) {
      ElMessage.error("您的余额不足，不能使用余额支付");
      return;
    }
  }

  if (addressId.value == null) {
    ElMessage.error("收货地址不能为空");
    return;
  }

  let payResult = await wxPay({ cartList: list.value, totalFee: total.value, totalScore: totalScore.value, payType: checked.value, payway: 'NATIVE', addressId: addressId.value });
  if (payResult?.code === 200) {
    console.log("payresult==", payResult);
    if (checked.value == "1" || checked.value == "3") {
      payDialogVisible.value = true;
      qrCodeUrl.value = payResult.data.codeUrl
      orderId.value = payResult.data.orderId
    } else {
      ElMessage({
        message: payResult?.msg,
        type: "success"
      })
      router.go(-1);
    }
  }

  let startTime = 10;
  function keepalive () {
    if (startTime === 10) {
      startTime = new Date().getTime()
    }
    console.log((new Date().getTime() - startTime) / 1000)
    // 超过1分钟则停止轮询
    if ((new Date().getTime() - startTime) > 60 * 1000) {
      ElMessage({
        message: '支付时间超过一分钟，请刷新！',
        type: "error"
      })
      orderId.value=''
      clearInterval(timer)
      socket.close()
      return
    }
    var chatMsg = {}
    chatMsg.type = 4
    //支付类型
    chatMsg.status = "1"
    chatMsg.orderId = orderId.value
    chatMsg.senderId = getUserId()
    socket.send(JSON.stringify(chatMsg))
    console.log("发送心跳消息")
  }
  function onConnect () {
    timer = setInterval(function () {
      keepalive()
    }, 10000)
  }
  if(orderId.value!=null&&orderId.value!==''){
    socket = new WebSocket(socketUrl)
    //打开事件
    socket.onopen = function () {
      onConnect()
    }
    socket.onmessage = function (e) {
      //alert(e.data)
      console.log('eeee========', e)
      const datas = JSON.parse(e.data)
      if (datas.tradeState == '1') {
        ElMessage({
          message: '支付成功',
          type: "success"
        })
        orderId.value=''
        clearInterval(timer)
        socket.close()
        payDialogVisible.value = false
      }
    }
  }
}
const closePayDialog = () => {
  ElMessageBox.confirm('确定退出支付吗?')
    .then(() => {
      //还要关闭socket和轮询
      orderId.value=''
      clearInterval(timer)
      socket.close()
      payDialogVisible.value = false
    })
    .catch(() => {
      // catch error
    })
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  //height: calc(100vh-30vh);
  background-color: #f5f5f5;
  padding-top: 30px;
  &_block {
    margin: 0 auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_top {
      width: 100%;
      background-color: #fff;
      padding: 30px 30px 30px 30px;
      margin-bottom: 30px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      &_firstline {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &_left {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &_left img {
          height: 20px;
        }
        &_right {
        }
      }
      &_addressList {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        &_item--default {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 100);
          text-align: center;
          padding: 20px 20px 20px 20px;
          margin-bottom: 20px;
          border: 1px solid rgba(255, 153, 89, 100);
        }
        &_item--unselect {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 100);
          text-align: center;
          padding: 20px 20px 20px 20px;
          margin-bottom: 20px;
          border: 1px solid rgba(189, 189, 189, 100);
        }
        &_item {
        }
        &_item img {
          height: 20px;
        }
      }
      &_collaps {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      &_collaps img {
        height: 16px;
        margin-left: 5px;
      }
    }
    &_center {
      width: 100%;
      background-color: #fff;
      padding: 30px 30px 30px 30px;
      margin-bottom: 30px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &_firstline {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &_firstline img {
        height: 20px;
      }
    }
    &_bottom {
      width: 100%;
      background-color: #fff;
      padding: 30px 30px 30px 30px;
      margin-bottom: 30px;
      border-radius: 6px;
      &_sub {
        margin-top: 60px;
        margin-bottom: 30px;
      }
      &_btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 30px;
        &_item--choosed {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 6px;
          background-color: rgba(255, 255, 255, 100);
          font-size: 14px;
          text-align: center;
          padding: 20px 20px 20px 20px;
          border: 1px solid rgba(255, 153, 89, 100);
          color: rgba(16, 16, 16, 100);
        }
        &--normal {
          border: 1px solid rgba(189, 189, 189, 100);
          color: rgba(16, 16, 16, 100);
        }

        &_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 6px;
          background-color: rgba(255, 255, 255, 100);
          font-size: 14px;
          text-align: center;
          padding: 20px 20px 20px 20px;
          border: 1px solid rgba(189, 189, 189, 100);
          color: rgba(16, 16, 16, 100);
        }
        &_item--choosed img {
          height: 30px;
        }
        &_item img {
          height: 30px;
        }
      }
    }
    &_itemwraper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &_item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      &_check {
        margin-right: 20px;
      }
      &_pic {
        margin-right: 30px;
      }
      &_pic img {
        height: 150px;
      }
      &_info {
        flex: 1;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &_title {
          font-size: 20px;
          font-weight: bold;
          color: #212121;
        }
        &_price {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 20px;
          &_tag {
            color: #ff6200;
            font-size: 28px;
            margin-right: 5px;
          }
          &_unit {
            color: #000000;
            font-size: 16px;
          }
        }
        &_score {
          margin-top: 30px;
          border-radius: 2px;
          background-color: rgba(255, 255, 255, 100);
          text-align: center;
          border: 1px solid rgba(255, 145, 0, 100);
          padding: 10px 10px;
          color: #ff9100;
          font-size: 14px;
        }
      }
    }
  }
}
:deep(.el-button--warning) {
  width: 260px;
  height: 60px;
  color: #3b2f00;
  background-color: #ffcf1a;
  border: 1px solid #ffcf1a;
  font-size: 20px;
}
.paydialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 90px;

  &_recharge {
    color: #212121;
    font-size: 20px;
    margin-bottom: 30px;
    &_money {
      margin-left: 5px;
      color: #ff9959;
    }
  }
  &_qrcode {
    margin-top: 10px;
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_btn {
    margin-top: 30px;
    background: #52cc6f;
    padding: 25px 15px 25px 15px;
    border-radius: 6px;
    color: white;
    font-size: 20px;
  }
  &_btn img {
    height: 30px;
    vertical-align: middle;
    margin-right: 10px;
  }
}
</style>
